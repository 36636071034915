@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_Rg.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_Bd.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_BdIt.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_It.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_Lt.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/Aller_LtIt.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Aller';
  src: url(./theme/fonts/AllerDisplay.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  --paolaVeroneseGreen: #009B77;
  --marigold: #F2A900;
  --sheenGreen: #97D700;
  --white: #FFFFFF;
  --degradado: linear-gradient(90deg, #009B77 0%, #97D700 100%);
  --orangeRedCrayola: #FF6161;
  --titaniumYellow: #F2EA27;
  --goldFusion: #856D37;
  --artichoke: #78845D;
  --dimGray: #696969;
  --quickSilver: #A1A1A1;
  --mantis: #78D868;
  --maximumBlue: #53B4D2;
  --tenneTawny: #D74D00;
  --jet: #292929;
  --grayWeb: #7D7D7D;
  --grayWebOpacity: rgba(125, 125, 125, 0.5);
  --spanishGray: #8E8E8E;
  --gainsboro: #DDDDDD;
  --erieBlack: #1F1F1F;
  font-family: Aller, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/background.jpg);
  background-size: cover;
  background-position: center;
  color: var(--white);
}

h1 {
  color: var(--marigold);
  font-size: 50px;
}

h2 {
  color: var(--white);
  font-weight: bold;
  font-size: 36px;
}

h3 {
  color: var(--white);
  font-weight: bold;
  font-size: 20px;
}

h4 {
  color: var(--white);
  font-weight: normal;
  font-size: 18px;
}

p {
  font-size: 14px;
  font-weight: normal;
  color: var(--white);
}

.dafo-option:hover{
  background-color: var(--artichoke);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--quickSilver);
  border-radius: 4px;
}

.modal-tag-stage4:hover {
  background-color: var(--dimGray);
}

.modal-tag-text {
  margin-block-start: 0;
  font-size: 14px;
  margin-block-end: 12px;
  width: 100%;
  font-weight: normal;
}

.axis-label {
  fill: white;
  font-weight: bold;
}

.legend-root {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  flex: 1
}

.legend-label {
  white-space: nowrap;
}

.MuiListItem-root {
  width: auto !important;
}
